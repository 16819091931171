/* Global styles with a dark theme */
body, html {
  margin: 0;
  padding: 0;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text */
  font-family: 'Arial', sans-serif;
}

/* Sidebar styles */
.sidebar {
  width: 200px;
  background-color: #242424; /* Slightly lighter dark shade for sidebar */
  color: #ffffff;
  position: fixed; /* Make sidebar fixed to the left */
  top: 60px;
  bottom: 0;
  overflow-y: auto; /* In case of overflow */
}

.sidebar h2 {
  margin-bottom: 1rem;
  padding: 0px 20px;
}

.sidebar p {
  margin-bottom: 0.5rem;
  padding: 0px 20px;
  cursor: pointer;
}

.sidebar p:hover {
  color: #bbbbbb;
}

/* Main content styles */
.content {
  margin-left: 200px; /* Sidebar width + some extra space */
  margin-top: 60px; /* Sidebar width + some extra space */
  padding: 20px;
  position: relative;
}

/* Game list and item styles */
.game-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.game-item {
  width: 180px; /* Fixed width for each game item */
  border: 1px solid #333333;
  padding: 10px;
  background-color: #1e1e1e; /* Dark background for game items */
  color: #e0e0e0;
}

.game-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

/* Style the dropdown container */
.sort-dropdown {
  display: flex;
  justify-content: left; /* Center the dropdown in its container */
  margin: 20px 10px; /* Add some margin for spacing */
}

/* Style the dropdown select element */
.sort-dropdown select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd; /* A light gray border */
  background-color: white;
  font-size: 1rem; /* Adjust font size as needed */
  cursor: pointer; /* Change cursor to indicate it's clickable */
}

/* Style for the dropdown options */
.sort-dropdown select option {
  padding: 10px; /* Add padding for options */
}

/* Add some hover effect if needed */
.sort-dropdown select:hover {
  border-color: #ccc; /* Slightly darker border on hover */
}

/* Style the top bar */
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  height: 60px;
}

.top-bar input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
  padding: 8px;
}

/* Style the search container within the top bar */
.top-bar .search-container {
  flex-grow: 1; /* Allows the search container to grow and fill space */
  margin-right: 20px; /* Add some space between search bar and login button */
}

/* Style the login button */
.login-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
/* Style the top bar */
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .content {
    margin-left: 0;
  }
}
